import React, { useEffect, useRef, useState } from "react"
import { loadGadgetQuotationSendingUrl, loadTenantMigrationStatusByDBName, loadUseProjectVariationLink, sendProjectVariationLinkAnswer } from "../store/actions/app"
import useDispatch from "../utils/useDispatch"
import { withRouter } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "../root"
import styled from "styled-components"
import Card from "../components/Card"
import { useTranslation } from "react-i18next"
import { FormControl, FormGroup, Radio } from "react-bootstrap"
import SignatureCanvas from 'react-signature-canvas'
import { Button } from "react-bootstrap"

const ErrorPage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
`

const ProjectVariationLinkView = ({match}) => {
    const {tenant, email, linkToken} = match.params
    const dispatch = useDispatch()
    const projectVariationLinkResponse = useSelector((store: RootState) => store.app.projectVariationLinkResponse)
    const loadingProjectVariationLinkResponse = useSelector((store: RootState) => store.app.loadingProjectVariationLinkResponse)
    const sendingProjectVariationLinkAnswer = useSelector((store: RootState) => store.app.sendingProjectVariationLinkAnswer)
    const loadingTenantMigrationStatus = useSelector(store => store.app.loadingTenantMigrationStatus)
    const loadingGadgetVariationSendingUrl = useSelector(store => store.app.loadingGadgetVariationSendingUrl)
    const [t] = useTranslation()
    const [signatureName, setSignatureName] = useState("")
    const [answer, setAnswer] = useState("")
    const [answerHasBeenSubmitted, setAnswerHasBeenSubmitted] = useState(false)
    const signaturePad: any = useRef()
    const [comment, setComment] = useState("")
    const [signaturePadIsEmpty, setSignaturePadIsEmpty] = useState(true)

    useEffect(()=>{
        dispatch(loadUseProjectVariationLink(tenant, email, linkToken)).then(res => {

            const variationId = res.projectVariation.id;

            dispatch(loadTenantMigrationStatusByDBName(tenant))
            .then(res => {
    
                const migrationStatus = res.tenant_migration_status;
    
                if(migrationStatus === 'successful') {
    
                    dispatch(loadGadgetQuotationSendingUrl(tenant, variationId)).then(res => {
                        const url = res.data.url;
                        window.location.href = url;
    
                        return;
                    });
                    
                }
            })
        })
    }, [])

    if(!projectVariationLinkResponse || loadingProjectVariationLinkResponse || loadingGadgetVariationSendingUrl || loadingTenantMigrationStatus) {
        return <div>
            <div className="loader-icon"/>
        </div>
    }

    if(projectVariationLinkResponse.access === false) {
        return <ErrorPage>
            <Card style={{padding: "2em"}}>
                <i style={{marginRight:"1em"}} className="fa fa-unlink"/> {t("TheProjectVariationLinkIsConsumed")}
            </Card>
        </ErrorPage>
    }

    if(answerHasBeenSubmitted === true) {
        return <ErrorPage>
            <Card style={{padding: "2em"}}>
                <div>
                    <b>{t("YourAnswerHasBeenSubmitted")}!</b>
                </div>
                <div>
                    {t("YouCanCloseThisWindowNow")}
                </div>
            </Card>
        </ErrorPage>
    }

    const handleChange = (e) => {
        setSignatureName("")
        setAnswer(e.target.value)
        clearSignaturePad()
    }

    const companyLogo = projectVariationLinkResponse.companyLogo
    const projectVariation = projectVariationLinkResponse.projectVariation
    const tenantName = projectVariationLinkResponse.tenantName

    const dataURItoBlob = (dataURI) => {
        var binary:any = atob(dataURI.split(',')[1]);
        var array:any = [];
        for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
    };

    const submitAnswer = () => {

        const signature = signaturePadIsEmpty ? undefined : dataURItoBlob(signaturePad.current.toDataURL());

        dispatch(sendProjectVariationLinkAnswer(tenant, email, linkToken, answer, comment, signature, signatureName))
            .then((res)=>{
                if(res.success) {
                    setAnswerHasBeenSubmitted(true)
                }
            })
    }

    const clearSignaturePad = () => {
        setSignaturePadIsEmpty(true)
        if(signaturePad.current) {
            signaturePad.current.clear();
        }
    }

    const onDrawEnd = () => {
        const isEmpty = signaturePad.current.isEmpty()
        setSignaturePadIsEmpty(isEmpty)
    }

    return <div style={{padding: "1em", display: "flex", justifyContent:"center", paddingBottom: "6em"}}>
        <div>
            <h2 style={{marginBottom: 0}}>
                {t("ProjectVariation")}: #{projectVariation.id} {projectVariation.Title}
            </h2>
            <div style={{color: "grey"}}>
                {t("SentBy")} {tenantName}
            </div>
            <br/>
            <div>
                {projectVariation.Description}
            </div>
            {projectVariation.FixedPrice && <div>
                {t("FixedPrice")}: {projectVariation.FixedPrice} kr
            </div>}
            <br/>
            <div>
                {t("AnswerBySelectingOneOfTheFollowingOptionsAndPressSend")}
            </div>
            <br/>
            <div>
                <label>{t("Answer")} *</label>
            </div>
            <Radio value="accepted" onChange={handleChange} checked={answer === "accepted"}>
                {t("Accept")}
            </Radio>
            <Radio value="rejected" onChange={handleChange} checked={answer === "rejected"}>
                {t("Reject")}
            </Radio>
            <br/>
            <div>
                {answer === "accepted" && <>
                    <div>
                        <label>{t("Signature")} *</label>
                    </div>
                    <div style={{color: "grey"}}>
                        <i>{t("PleaseSignHereToAccept")}</i>
                    </div>
                    <SignatureCanvas 
                        ref={signaturePad}
                        canvasProps={{width: 300, height: 220, className: 'sigCanvas'}} 
                        onEnd={onDrawEnd}
                    />
                    <div>
                        <Button disabled={signaturePadIsEmpty} onClick={clearSignaturePad}>{t("ClearSignature")}</Button>
                    </div>
                    <br/>
                    <div>
                        <FormGroup>
                            <label>{t("NameClarification")} *</label>
                            <FormControl style={{width: 300}} value={signatureName} onChange={(e:any) => {setSignatureName(e.target.value)}} />
                        </FormGroup>
                    </div>
                </>}
                <FormGroup>
                    <label>{t("Comment")}</label>
                    <FormControl style={{resize: "vertical", width: 300}} componentClass="textarea" value={comment} onChange={(e:any) => {setComment(e.target.value)}} />
                </FormGroup>
            </div>
            <br/>
            <div>
                * {t("indicatesRequiredField")}
            </div>
            <br/>
            <Button disabled={(answer === "accepted" && (signatureName === "" || signaturePadIsEmpty)) || answer === "" || sendingProjectVariationLinkAnswer} bsStyle="primary" onClick={submitAnswer}>
                <div style={{display:"flex", alignItems:"center"}}>
                    {sendingProjectVariationLinkAnswer && <div style={{marginRight: 4}} className="loader-icon"/>}
                    <span>{sendingProjectVariationLinkAnswer ? t("SendingResponse") : t("SendResponse")}</span>
                </div>
            </Button>
        </div>
    </div>
}

export default withRouter(ProjectVariationLinkView)
